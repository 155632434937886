<script setup lang="ts">
import { ref } from 'vue';
import { getColor } from '@/utils/people';
import { VImg } from 'vuetify/lib/components/index.mjs';

const props = withDefaults(
  defineProps<{
    name: string;
    image?: string;
    imageProps?: VImg['$props'];
    size?: number;
    letterClass?: string;
  }>(),
  { size: 36, name: 'N' },
);

const imageFailed = ref(false);
</script>

<template>
  <v-avatar :size="props.size" :color="`${getColor(props.name)}-100`">
    <v-img
      v-if="props.image && !imageFailed"
      cover
      v-bind="props.imageProps"
      :src="props.image"
      :alt="props.name"
      @error="imageFailed = true"
    />
    <span
      v-else
      class="uppercase"
      :class="[letterClass, `text-${getColor(props.name)}-700`]"
      v-text="props.name?.[0] ?? 'N'"
    />
  </v-avatar>
</template>

<style scoped></style>
