<script setup lang="ts">
import { ChevronDownIcon, PlusIcon, CrownIcon } from 'vue-tabler-icons';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { useWorkspacesMenu } from './hooks';

const {
  isDropdownOpen,
  currentWorkspace,
  areWorkspacesLoading,
  workspaces,
  primaryWorkspaceId,
  changeCurrentWorkspace,
} = useWorkspacesMenu();
</script>

<template>
  <v-skeleton-loader
    v-if="areWorkspacesLoading || !currentWorkspace"
    class="rounded w-full h-10"
  />
  <v-menu v-else v-model="isDropdownOpen" location="bottom">
    <template #activator="{ props: menu }">
      <v-btn
        v-bind="menu"
        elevation="0"
        color="transparent"
        height="40"
        class="workspace-select"
        :ripple="{ class: 'text-primary' }"
      >
        <template #default>
          <span>{{ currentWorkspace.name }}</span>
        </template>
        <template #append>
          <Icon
            :src="ChevronDownIcon"
            class="w-4 h-4 text-grey-90"
            :class="{ 'rotate-180': isDropdownOpen }"
          />
        </template>
      </v-btn>
    </template>
    <v-list
      elevation="0"
      class="py-0 border border-solid border-grey-10 rounded-[8px]"
    >
      <v-list-item
        v-for="{ id, name } in workspaces"
        :key="id"
        v-tooltip="primaryWorkspaceId === id && 'This is a primary workspace'"
        class="workspace-select__list-item"
      >
        <v-btn
          elevation="0"
          variant="text"
          :ripple="{ class: 'text-primary' }"
          class="workspace-select__list-item__btn"
          @click="changeCurrentWorkspace(id)"
        >
          <template #default>
            <span>{{ name }}</span>
          </template>
          <template v-if="primaryWorkspaceId === id" #append>
            <CrownIcon class="w-4 h-4 text-grey-90" />
          </template>
        </v-btn>
      </v-list-item>
      <v-list-item
        class="workspace-select__list-item border-t border-solid border-grey-10"
      >
        <v-btn
          elevation="0"
          variant="text"
          :ripple="{ class: 'text-primary' }"
          class="workspace-select__list-item__btn"
          :to="{ name: ROUTE_NAMES.CREATE_WORKSPACE }"
        >
          <template #prepend>
            <div class="flex items-center justify-center w-6 h-6">
              <Icon :src="PlusIcon" class="w-4 h-4 text-grey-90" />
            </div>
          </template>
          <template #default>
            <span>Create a workspace</span>
          </template>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style>
.workspace-select {
  @apply flex items-center gap-x-2 p-2 border border-solid border-grey-10 rounded-[8px];
}

.workspace-select .v-btn__content {
  @apply flex justify-start grow typo-body1 font-semibold overflow-ellipsis;
}

.workspace-select__list-item {
  @apply p-0 min-h-0 !important;
}

.workspace-select__list-item__btn {
  @apply flex items-center gap-x-2 p-2 w-full rounded-none;
}

.workspace-select__list-item__btn .v-btn__overlay {
  @apply bg-primary;
}

.workspace-select__list-item__btn .v-btn__content {
  @apply flex justify-start grow typo-body1 overflow-ellipsis;
}

.workspace-select .v-btn__overlay,
.workspace-select__list-item__btn .v-btn__overlay {
  @apply bg-primary;
}

.workspace-select .v-btn__prepend,
.workspace-select .v-btn__append,
.workspace-select__list-item__btn .v-btn__prepend,
.workspace-select__list-item__btn .v-btn__append {
  @apply m-0;
}
</style>
