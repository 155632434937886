<script setup lang="ts">
import { reactive, watch } from 'vue';
import { ChevronDownIcon, XIcon, MailIcon } from 'vue-tabler-icons';
import { isEmail } from '@/utils';
import LetterAvatar from '@/components/common/molecules/LetterAvatar.vue';

const props = withDefaults(
  defineProps<{
    rules:
      | (([T]: string[] | string) => string | true)[]
      | (([T]: string[]) => string | true)[];
    modelValue: string[];
    isLoading?: boolean;
    placeholder?: string;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
    isLoading: false,
    placeholder: 'Type emails to invite new teammates...',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

watch(
  () => props.modelValue,
  (newValue: string[]) => {
    state.emails = newValue;
  },
);

const state = reactive({
  items: [], //always empty, used for v-combobox initialization
  emails: props.modelValue,
});

function removeEmail(email: string) {
  emit(
    'update:modelValue',
    state.emails.filter((el) => el !== email),
  );
}

function toggleItem(item: string) {
  if (state.emails.includes(item)) {
    removeEmail(item);
  } else {
    emit('update:modelValue', [...state.emails, item]);
  }
}
</script>

<template>
  <v-combobox
    ref="input"
    v-model="state.emails"
    validate-on="blur"
    :items="state.items"
    variant="outlined"
    multiple
    :placeholder="props.placeholder"
    class="basis-full max-h-[200px]"
    :menu-icon="ChevronDownIcon"
    :rules="rules"
    :disabled="disabled"
    :loading="isLoading"
    hide-details="auto"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <template #selection="{ attrs, item, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :input-value="selected"
        label
        small
        class="bg-primary-1 border rounded-sm mr-[1px] max-h-6"
        :class="isEmail(item.value) ? 'text-primary' : 'text-alerts-error'"
      >
        <span class="pr-2">
          {{ item.value }}
        </span>
        <Icon
          class="cursor-pointer hover:text-alerts-error"
          size="16"
          :src="XIcon"
          @click="removeEmail(item.title)"
        />
      </v-chip>
    </template>
    <template #item="{ item }">
      <div
        v-if="item"
        class="flex gap-x-1 p-2 cursor-pointer"
        @click="toggleItem(item.value)"
      >
        <v-icon :icon="MailIcon" />
        <LetterAvatar :name="item.value" :size="24" />
        <span class="typo-body1 text-black">{{ item.value }}</span>
      </div>
    </template>
  </v-combobox>
</template>

<style scoped>
.v-combobox :deep(.v-input__control) .v-field__input input {
  @apply my-1 text-sm;
}

.v-combobox :deep(.v-combobox__menu-icon) {
  @apply text-grey-90;
}
</style>
