<script setup lang="ts">
import { defineModel } from 'vue';
import { CheckIcon } from 'vue-tabler-icons';
import { SPACE_COLORS } from '@/constants/spaceColors';

const model = defineModel({ required: true });
</script>
<template>
  <div class="flex flex-col gap-y-2">
    <span class="typo-body1">Color</span>
    <div class="flex items-center gap-x-2">
      <label
        v-for="color in SPACE_COLORS"
        :key="color"
        :class="`relative w-8 h-8 bg-${color}-100 rounded-lg cursor-pointer`"
      >
        <input
          v-model="model"
          name="manage-folder-popup__color-btn"
          type="radio"
          class="w-full h-full invisible z-20"
          :value="color"
        />
        <Icon
          :src="CheckIcon"
          class="w-6 h-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all opacity-0"
          :class="{ 'opacity-100': model === color }"
        />
      </label>
    </div>
  </div>
</template>
