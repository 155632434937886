<script setup lang="ts">
import { ref } from 'vue';
import { UserIcon, PencilIcon, CameraIcon } from 'vue-tabler-icons';
import {
  MAX_SIZE,
  ALLOWED_MIME_TYPES,
  MaxSizeExceededError,
  InvalidMimeTypeError,
} from 'trpc_backend/src/domain/users/avatar.rules';
import type { AvatarProps } from '@/api/types';
import { getColor } from '@/utils/people';
import { showError } from '@/utils/alert';

const props = withDefaults(
  defineProps<{
    person: AvatarProps;
    size?: string | number;
    isEditable?: boolean;
    letterClass?: string;
  }>(),
  { size: 12, letterClass: 'text-[12px]' },
);

defineEmits<{ (e: 'change', file: string): void }>();

const imageFailed = ref(false);

const name = props.person.name || props.person.email || '';
const [letter] = name;

const avatarValidator = (file: File): void => {
  if (file.size > MAX_SIZE) {
    const error = new MaxSizeExceededError();
    showError(error);
    throw error;
  }

  if (!ALLOWED_MIME_TYPES.includes(file.type)) {
    const error = new InvalidMimeTypeError();
    showError(error);
    throw error;
  }
};
</script>

<template>
  <div
    class="relative inline-block aspect-square rounded-full select-none"
    :class="[`w-${size}`, `h-${size}`]"
  >
    <img
      v-if="person.avatarUrl && !imageFailed"
      :src="person.avatarUrl"
      class="w-full h-full object-cover rounded-full"
      @error="imageFailed = true"
    />
    <v-avatar
      v-else-if="letter"
      class="w-full h-full flex rounded-full justify-center items-center uppercase"
      :size="props.size"
      :color="`${getColor(name)}-100`"
    >
      <span
        :class="`text-${getColor(name)}-700 ${letterClass}`"
        v-text="letter"
      />
    </v-avatar>
    <Icon v-else :src="UserIcon" class="w-full h-full" />
    <FilePicker
      v-if="isEditable"
      :accept="ALLOWED_MIME_TYPES.join(', ')"
      :validator="avatarValidator"
      class="absolute w-11 h-11 bottom-0 right-0 rounded-full bg-primary text-white flex justify-center items-center cursor-pointer hover:scale-105 duration-300 active:scale-100"
      @upload="(file: string) => $emit('change', file)"
    >
      <v-icon
        :icon="person.avatarUrl && !imageFailed ? PencilIcon : CameraIcon"
        :size="size"
      />
    </FilePicker>
  </div>
</template>
