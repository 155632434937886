<script setup lang="ts">
import MainMenu from './MainMenu.vue';
import SettingsMenu from './SettingsMenu.vue';
import WorkspaceSelect from './WorkspaceSelect.vue';
import ReferralProgram from './content/ReferralProgram.vue';
import { TopBar } from './components';
import { useSidebarSize } from './hooks';

const { isCollapsed } = useSidebarSize();
</script>

<template>
  <v-navigation-drawer
    class="pt-6 pb-4 px-2 left-1 bg-background-window"
    floating
    :rail="isCollapsed"
  >
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col gap-y-4">
        <TopBar />
        <WorkspaceSelect />
        <MainMenu />
      </div>
      <div class="flex flex-col gap-y-4">
        <SettingsMenu />
        <ReferralProgram />
      </div>
    </div>
  </v-navigation-drawer>
</template>
