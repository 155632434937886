<script setup lang="ts">
import MenuIcon from '@/components/common/molecules/MenuIcon.vue';
import { CONVERSATION_LANGUAGES } from '@/constants/conversationLanguages';
import { useGmeetLang } from '../hooks';

const { langCode, updateLang, isUpdating } = useGmeetLang();
</script>
<template>
  <div
    class="bg-white border border-solid border-grey-20 rounded p-2 max-w-[240px]"
  >
    <v-autocomplete
      v-model="langCode"
      placeholder="Select a language"
      hide-details
      :menu-icon="MenuIcon"
      class="gmeet-langs-autocomplete"
      :items="CONVERSATION_LANGUAGES"
      item-title="name"
      :loading="isUpdating"
      :disabled="isUpdating"
      @update:model-value="updateLang"
    >
      <template #item="{ props }">
        <v-list-item
          v-bind="props"
          class="gmeet-langs-autocomplete__list-item"
        />
      </template>
    </v-autocomplete>
  </div>
</template>
<style>
.gmeet-langs-autocomplete .v-field {
  @apply pl-1 pr-3 py-[7px] rounded border border-solid border-grey-20 bg-white typo-body1;
}

.gmeet-langs-autocomplete .v-field .v-field__overlay {
  @apply bg-transparent rounded;
}

.gmeet-langs-autocomplete .v-field__input {
  --v-input-control-height: auto;
  @apply p-0;
}

.gmeet-langs-autocomplete .v-field__input input {
  @apply mt-0 !important;
}

.gmeet-langs-autocomplete .v-field__outline::before,
.gmeet-langs-autocomplete .v-field__outline::after {
  display: none;
}

.gmeet-langs-autocomplete .v-progress-linear__indeterminate {
  @apply bg-primary;
}

.v-overlay__content:has(.gmeet-langs-autocomplete__list-item) {
  @apply rounded shadow-none border border-solid border-grey-20;
  @apply left-[12px] w-full max-w-[232px] !important;
}

.v-list:has(.gmeet-langs-autocomplete__list-item) {
  @apply py-2 rounded shadow-none !important;
}

.gmeet-langs-autocomplete__list-item.v-list-item {
  @apply py-[7px] px-2 !important;
  @apply min-h-0;
}

.gmeet-langs-autocomplete__list-item.v-list-item .v-list-item-title {
  @apply typo-body1;
}
</style>
